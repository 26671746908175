<template>
  <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
  <div class="min-h-screen w-full bg-teal-900 px-6">
    <AdminHeader />

    <!-- Stats Content -->
    <div class="flex justify-center w-full">
      <div class="w-full max-w-4xl px-4 md:px-8 lg:px-16 pb-8">
        <!-- Title with dot -->
        <div class="text-white text-2xl flex items-baseline mb-8">
          Stats
          <span class="ml-1 text-4xl leading-none" style="color: #00A388"
            >.</span
          >
        </div>

        <!-- Date Range Section -->
        <div class="mb-14">
          <div class="flex items-baseline gap-4 flex-col sm:flex-row">
            <div class="text-white text-md">Date</div>
            <div
              class="flex gap-4 flex-grow flex-col sm:flex-row w-full sm:w-auto"
            >
              <div class="relative flex items-center flex-1 min-w-[200px]">
                <input
                  ref="fromDate"
                  type="text"
                  v-model="fromDate"
                  placeholder="From"
                  class="bg-white rounded py-4 pl-4 pr-10 w-full text-sm cursor-pointer"
                  @click="openDatePicker($event, 'fromDate')"
                />
                <div
                  class="absolute right-3 cursor-pointer"
                  @click="openDatePicker($event, 'fromDate')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                </div>
              </div>
              <div class="relative flex items-center flex-1 min-w-[200px]">
                <input
                  ref="toDate"
                  type="text"
                  v-model="toDate"
                  placeholder="To"
                  class="bg-white rounded py-4 pl-4 pr-10 w-full text-sm cursor-pointer"
                  @click="openDatePicker($event, 'toDate')"
                />
                <div
                  class="absolute right-3 cursor-pointer"
                  @click="openDatePicker($event, 'toDate')"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Stats Grid -->
        <div class="space-y-8">
          <!-- Vendors -->
          <div>
            <div class="flex items-baseline gap-4 flex-wrap">
              <div class="text-white text-left w-16 text-md ">Vendors</div>
              <span class="text-white number-style"
                >#{{ stats.total_vendors }}</span
              >
            </div>
          </div>

          <!-- Users -->
          <div>
            <div class="flex items-baseline gap-4 flex-wrap">
              <div class="text-white text-md text-left w-16 ">Users</div>
              <div class="flex items-baseline gap-8 sm:gap-20 flex-wrap">
                <div class="flex items-baseline gap-3">
                  <span class="text-white number-style"
                    >#{{ stats.new_users }}</span
                  >
                  <span style="color: #00A388" class="text-sm">New Users</span>
                </div>
                <div class="flex items-baseline gap-3">
                  <span class="text-white number-style"
                    >#{{ stats.total_users }}</span
                  >
                  <span style="color: #00A388" class="text-sm"
                    >Total Users</span
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- Scans -->
          <div>
            <div class="flex items-baseline gap-4 flex-wrap">
              <div class="text-white text-md text-left w-16 ">Scans</div>
              <span class="text-white number-style"
                >#{{ stats.total_completed_scans }}</span
              >
            </div>
          </div>

          <!-- Messages -->
          <div>
            <div class="flex items-baseline gap-4 flex-wrap">
              <div class="text-white text-md text-left w-16">Messages</div>
              <span class="text-white number-style"
                >#{{ stats.total_messages }}</span
              >
            </div>
          </div>
        </div>

        <!-- Back Button -->
        <button
          @click="$router.back()"
          class="mt-14 bg-teal-800 gap-5 text-white px-6 py-4 rounded-md flex items-center space-x-2"
          style="background-color: #204952"
        >
          <span class="material-icons text-xl arrow-left-icon">arrow_left</span>
          <span>Back</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AdminHeader from "../../components/AdminHeader.vue";

import Loading from "vue-loading-overlay";

export default {
  name: "Stats",
  components: {
    AdminHeader,
    Loading
  },
  data() {
    return {
      logo_transition: false,
      fromDate: "",
      toDate: "",
      isLoading: false,
      fullPage: true,
      loader: "bars",
      stats: {
        total_users: 0,
        new_users: 0,
        total_vendors: 0,
        total_completed_scans: 0,
        total_messages: 0
      }
    };
  },
  watch: {
    fromDate(newVal) {
      if (newVal && this.toDate) {
        this.fetchStats();
      } else if (!newVal && !this.toDate) {
        this.fetchDefaultStats();
      }
    },
    toDate(newVal) {
      if (newVal && this.fromDate) {
        this.fetchStats();
      } else if (!newVal && !this.fromDate) {
        this.fetchDefaultStats();
      }
    }
  },
  mounted() {
    this.logo_transition = true;
    this.fetchDefaultStats();
  },
  methods: {
    openDatePicker(event, ref) {
      const input = this.$refs[ref];
      input.type = "date";
      input.showPicker();

      input.addEventListener(
        "blur",
        () => {
          if (!input.value) {
            input.type = "text";
          }
        },
        { once: true }
      );

      input.addEventListener("change", () => {
        if (input.value) {
          const date = new Date(input.value);
          const formattedDate = date.toISOString().split("T")[0];
          if (ref === "fromDate") {
            this.fromDate = formattedDate;
          } else {
            this.toDate = formattedDate;
          }
        }
      });
    },
    async fetchDefaultStats() {
      this.isLoading = true;
      try {
        const response = await axios.get("/users/count", {
    headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('access')).access_token}`
    }
});
        this.stats = response.data;
      } catch (error) {
        console.error("Error fetching default stats:", error);
        this.$swal.fire({
          icon: "error",
          title: error.response?.data?.message || "Error fetching stats",
          timer: 3000,
          toast: true,
          position: "top-end",
          showConfirmButton: false
        });
      } finally {
        this.isLoading = false;
      }
    },
    async fetchStats() {
      if (this.fromDate && this.toDate) {
        this.isLoading = true;
        try {
          const response = await axios.get(`/users/count`, {
            params: {
              from: this.fromDate,
              to: this.toDate
            }, 
    headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('access')).access_token}`
    }
          })
          this.stats = response.data;
        } catch (error) {
          console.error("Error fetching stats:", error);
        } finally {
          this.isLoading = false;
        }
      }
    }
  }
};
</script>

<style scoped>
@import "../../assets/css/dashboard.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.bg-teal-900 {
  background-color: #06262d;
}

input::placeholder {
  color: #707070;
}

input {
  color: #707070;
}

input::-webkit-calendar-picker-indicator {
  filter: invert(0.5);
}

.number-style {
  font-size: 44px;
  line-height: 53px;
  font-weight: 600;
}

.user-label {
  color: #00a388;
}

.custom-button {
  background-color: #00a388;
}
.arrow-left-icon {
  color: #00a388;
  background-color: #002f28;
  display: inline-flex;
  border-radius: 4px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  display: none;
}

input[type="date"] {
  -webkit-appearance: none;
  appearance: none;
  color: #707070;
}

/* For Firefox */
input[type="date"]::-moz-calendar-picker-indicator {
  display: none;
}

/* For Edge */
input[type="date"]::-ms-clear {
  display: none;
}

input[type="date"]::-ms-reveal {
  display: none;
}
</style>
